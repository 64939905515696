<template>
  <div class="header-box" id="header" ref="header" :class="{'homeBg': $route.path == '/','scrollBg': menuStatus}">
    <h1 class="logo">
      <router-link to="/">
        <svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 542.9 54.9" style="enable-background:new 0 0 542.9 54.9;" xml:space="preserve">
<radialGradient id="SVGID_1_" cx="58.2673" cy="656.4266" r="39.896" gradientTransform="matrix(1 0 0 1 0 -644.7086)" gradientUnits="userSpaceOnUse">
	<stop  offset="0" style="stop-color:#F8B500"/>
	<stop  offset="1" style="stop-color:#EA5504"/>
</radialGradient>
<path class="st0" d="M36.6,1c10.8,0,19.7,7.5,20.5,17c-5.1,0.1-10.2,4.1-15,9c0.4,0.5,0.8,0.9,1.3,1.4c1,1.1,2,2.1,2.9,3.1
	c5.6-5.9,13.4-10.8,22.2-6.7c2.6,3.1,4.1,7,4.1,11.1c0,10.2-9.2,18.6-20.6,18.6c-13,0-18,0-31,0C9.7,54.6,0.4,46.3,0.4,36
	c0-3.7,1.2-7.2,3.3-10.1C18.6,8,39,35.2,48.1,43.1c9.6,8.4,20.5-4.5,20.5-4.5c-21.6,7.6-24.6-17.4-43.7-22c-3.3-0.8-6.1-0.9-8.6-0.6
	C18.2,7.4,26.6,1,36.6,1L36.6,1z M36.3,32.9c-10.4,9.7-23.5,17.9-33.5,1.2c0,0,0.1,0.3,0.2,0.9c1.1,3.5,6.4,16.1,22.9,12.1
	c6.5-1.6,11-5.4,15-9.6C39.4,36,37.8,34.4,36.3,32.9z"/>
<path class="st1" d="M98.6,44.2c3,0,5.5-0.4,7.4-1.1c1.9-0.7,3.3-1.8,4.1-3.2c0.9-1.4,1.3-3.1,1.3-5.2c0-1.4-0.1-2.7-0.4-3.7
	c-0.3-1.1-0.8-2-1.5-2.7c-0.7-0.8-1.7-1.4-3-2c-1.3-0.6-2.9-1.1-4.9-1.5c-1.2-0.3-2.2-0.5-2.9-0.8c-0.8-0.2-1.3-0.5-1.7-0.8
	c-0.4-0.3-0.7-0.6-0.8-1c-0.1-0.4-0.2-0.8-0.2-1.3c0-0.9,0.2-1.6,0.6-2c0.4-0.5,1-0.8,1.7-1c0.8-0.2,1.8-0.3,3.1-0.3
	c0.7,0,1.4,0,2.2,0.1c0.8,0.1,1.6,0.2,2.3,0.3c0.8,0.1,1.5,0.2,2.1,0.4c0.6,0.1,1.2,0.3,1.6,0.4v-5.6c-0.9-0.3-2.1-0.6-3.5-0.8
	c-1.4-0.2-3.1-0.3-5-0.3c-2.6,0-4.8,0.3-6.6,0.9c-1.8,0.6-3.2,1.6-4.1,2.9C89.5,17.3,89,19,89,21.2c0,1.4,0.1,2.6,0.4,3.5
	c0.3,1,0.8,1.8,1.5,2.5c0.7,0.7,1.6,1.2,2.8,1.7c1.2,0.5,2.7,0.9,4.4,1.3c1.3,0.3,2.3,0.6,3.1,0.8c0.8,0.3,1.4,0.6,1.8,0.9
	c0.4,0.3,0.7,0.7,0.9,1.1c0.2,0.4,0.2,0.9,0.2,1.5c0,0.9-0.2,1.7-0.5,2.2c-0.4,0.5-1,0.9-1.8,1.2c-0.9,0.2-2,0.3-3.5,0.3
	c-0.8,0-1.7,0-2.6-0.1c-0.9-0.1-1.7-0.1-2.6-0.3c-0.8-0.1-1.6-0.2-2.3-0.4c-0.7-0.2-1.3-0.3-1.9-0.5v6c0.7,0.2,1.5,0.4,2.6,0.5
	c1,0.2,2.1,0.3,3.3,0.4C96,44.1,97.3,44.2,98.6,44.2"/>
<polygon class="st1" points="139.5,12.6 114.8,12.6 114.8,18.5 123.5,18.5 123.5,43.7 130.8,43.7 130.8,18.5 139.5,18.5 "/>
<path class="st1" d="M167.2,43.7h7.7l-12.3-31.2h-9.8l-12.4,31.2h7.6l2.9-7.7h13.5L167.2,43.7z M153,30.4l4.4-11.8h0.6l4.3,11.8H153
	z"/>
<path class="st1" d="M205.9,43.7l-9.3-12.1c1.4-0.4,2.6-1,3.5-1.8c0.9-0.8,1.6-1.8,2-3c0.4-1.2,0.7-2.7,0.7-4.4
	c0-2.4-0.4-4.3-1.3-5.8c-0.9-1.5-2.3-2.5-4.2-3.2c-1.9-0.7-4.3-1-7.3-1h-11.3v31.2h7.3V32.4h3.6l7.8,11.4H205.9z M186,27.3V18h2.9
	c1.2,0,2.3,0.1,3.1,0.2c0.9,0.1,1.5,0.3,2.1,0.7c0.5,0.3,0.9,0.8,1.2,1.4c0.2,0.6,0.4,1.3,0.4,2.3c0,1-0.1,1.8-0.4,2.4
	c-0.2,0.6-0.6,1.1-1.2,1.5c-0.5,0.4-1.2,0.6-2.1,0.7c-0.9,0.1-1.9,0.2-3.1,0.2H186z"/>
<polygon class="st1" points="231.6,12.6 207,12.6 207,18.5 215.7,18.5 215.7,43.7 222.9,43.7 222.9,18.5 231.6,18.5 "/>
<path class="st1" d="M248.5,43.7h12.2c2.4,0,4.4-0.2,6-0.6c1.6-0.4,2.9-1,3.8-1.8c0.9-0.8,1.6-1.7,2-2.8c0.4-1.1,0.6-2.4,0.6-3.8
	c0-2-0.5-3.5-1.5-4.7c-1-1.2-2.4-2-4.4-2.5v-0.2c1.2-0.3,2.2-0.8,2.9-1.4c0.7-0.6,1.2-1.3,1.5-2.1c0.3-0.8,0.5-1.8,0.4-2.9
	c0-1.4-0.2-2.6-0.6-3.7c-0.4-1-1.1-1.9-2-2.6c-0.9-0.7-2.2-1.2-3.8-1.6c-1.6-0.4-3.6-0.5-6-0.5h-11.2V43.7z M255.8,25.4V18h2.4
	c1.2,0,2.2,0,3.1,0.1c0.9,0.1,1.5,0.3,2.1,0.5c0.5,0.3,0.9,0.6,1.2,1.1c0.3,0.5,0.4,1.1,0.4,1.9c0,1-0.2,1.8-0.7,2.3
	c-0.5,0.5-1.2,0.9-2.2,1.1c-1,0.2-2.3,0.3-3.8,0.3H255.8z M255.8,38.3v-7.9h3.4c1.3,0,2.4,0.1,3.2,0.2c0.9,0.1,1.5,0.4,2,0.7
	c0.5,0.3,0.9,0.7,1.1,1.2c0.2,0.5,0.3,1.1,0.3,1.9c0,1.1-0.2,1.9-0.6,2.4c-0.4,0.6-1.1,1-2,1.2c-1,0.2-2.3,0.3-4,0.3H255.8z"/>
<rect x="279.5" y="12.6" class="st1" width="7.3" height="31.2"/>
<path class="st1" d="M308.3,44.2c2,0,3.7-0.1,5.3-0.4c1.6-0.3,3-0.8,4.2-1.5c1.2-0.7,2.2-1.7,3.1-2.9c0.8-1.2,1.4-2.8,1.9-4.6
	c0.4-1.8,0.6-4,0.6-6.6c0-2.5-0.2-4.7-0.6-6.6c-0.4-1.8-1-3.4-1.9-4.6c-0.8-1.2-1.8-2.2-3.1-2.9c-1.2-0.7-2.6-1.2-4.2-1.5
	c-1.6-0.3-3.4-0.5-5.3-0.5c-1.9,0-3.7,0.1-5.3,0.5c-1.6,0.3-3,0.8-4.2,1.5c-1.2,0.7-2.2,1.7-3.1,2.9c-0.8,1.2-1.5,2.8-1.9,4.6
	c-0.4,1.8-0.6,4-0.6,6.6c0,2.5,0.2,4.7,0.6,6.6c0.4,1.8,1.1,3.4,1.9,4.6c0.8,1.2,1.9,2.2,3.1,2.9c1.2,0.7,2.6,1.2,4.2,1.5
	C304.6,44,306.4,44.2,308.3,44.2 M308.3,38.5c-1.5,0-2.8-0.2-3.7-0.5c-1-0.4-1.7-1-2.3-1.8c-0.5-0.8-0.9-1.9-1.1-3.2
	c-0.2-1.3-0.3-2.9-0.3-4.9c0-1.9,0.1-3.5,0.3-4.8c0.2-1.3,0.6-2.4,1.1-3.2c0.5-0.8,1.3-1.4,2.3-1.8c1-0.4,2.2-0.6,3.7-0.6
	c1.5,0,2.8,0.2,3.7,0.6c1,0.4,1.7,1,2.3,1.8c0.5,0.8,0.9,1.9,1.1,3.2c0.2,1.3,0.3,2.9,0.3,4.8c0,1.9-0.1,3.5-0.3,4.9
	c-0.2,1.3-0.6,2.4-1.1,3.2c-0.5,0.8-1.3,1.4-2.3,1.8C311.1,38.3,309.8,38.5,308.3,38.5"/>
<path class="st1" d="M338.5,44.2c3,0,5.5-0.4,7.4-1.1c1.9-0.7,3.3-1.8,4.1-3.2c0.9-1.4,1.3-3.1,1.3-5.2c0-1.4-0.1-2.7-0.4-3.7
	c-0.3-1.1-0.8-2-1.5-2.7c-0.7-0.8-1.7-1.4-3-2c-1.3-0.6-2.9-1.1-4.9-1.5c-1.2-0.3-2.2-0.5-2.9-0.8c-0.7-0.2-1.3-0.5-1.7-0.8
	c-0.4-0.3-0.7-0.6-0.8-1c-0.1-0.4-0.2-0.8-0.2-1.3c0-0.9,0.2-1.6,0.5-2c0.4-0.5,0.9-0.8,1.7-1c0.8-0.2,1.8-0.3,3.1-0.3
	c0.7,0,1.4,0,2.2,0.1c0.8,0.1,1.6,0.2,2.3,0.3c0.8,0.1,1.5,0.2,2.1,0.4c0.6,0.1,1.2,0.3,1.6,0.4v-5.6c-0.9-0.3-2.1-0.6-3.5-0.8
	c-1.4-0.2-3.1-0.3-5-0.3c-2.6,0-4.8,0.3-6.6,0.9c-1.8,0.6-3.2,1.6-4.1,2.9c-0.9,1.3-1.4,3.1-1.4,5.3c0,1.4,0.1,2.6,0.4,3.5
	c0.3,1,0.8,1.8,1.5,2.5c0.7,0.7,1.6,1.2,2.8,1.7c1.2,0.5,2.7,0.9,4.4,1.3c1.3,0.3,2.3,0.6,3.1,0.8c0.8,0.3,1.4,0.6,1.8,0.9
	c0.4,0.3,0.7,0.7,0.9,1.1c0.2,0.4,0.2,0.9,0.2,1.5c0,0.9-0.2,1.7-0.5,2.2c-0.4,0.5-1,0.9-1.8,1.2c-0.9,0.2-2,0.3-3.5,0.3
	c-0.8,0-1.7,0-2.6-0.1c-0.9-0.1-1.7-0.1-2.6-0.3c-0.8-0.1-1.6-0.2-2.3-0.4c-0.7-0.2-1.3-0.3-1.9-0.5v6c0.7,0.2,1.5,0.4,2.6,0.5
	c1,0.2,2.1,0.3,3.3,0.4C336,44.1,337.2,44.2,338.5,44.2"/>
<polygon class="st2" points="364.8,18.5 373.4,18.5 377.9,18.5 377.9,12.6 357.6,12.6 357.6,18.5 "/>
<polygon class="st1" points="364.8,37.8 364.8,30.6 376.9,30.6 376.9,24.9 373.4,24.9 364.8,24.9 357.6,24.9 357.6,43.7 378.5,43.7 
	378.5,37.8 "/>
<path class="st1" d="M411.7,43.7l-9.3-12.1c1.4-0.4,2.6-1,3.5-1.8c0.9-0.8,1.6-1.8,2-3c0.4-1.2,0.7-2.7,0.7-4.4
	c0-2.4-0.4-4.3-1.3-5.8c-0.9-1.5-2.3-2.5-4.2-3.2c-1.9-0.7-4.3-1-7.3-1h-11.3v31.2h7.3V32.4h3.6l7.8,11.4H411.7z M391.7,27.3V18h2.9
	c1.2,0,2.3,0.1,3.1,0.2c0.9,0.1,1.5,0.3,2.1,0.7c0.5,0.3,0.9,0.8,1.2,1.4c0.2,0.6,0.4,1.3,0.4,2.3c0,1-0.1,1.8-0.4,2.4
	c-0.2,0.6-0.6,1.1-1.2,1.5c-0.5,0.4-1.2,0.6-2.1,0.7c-0.9,0.1-1.9,0.2-3.1,0.2H391.7z"/>
<polygon class="st1" points="423.9,43.7 433,43.7 444.7,12.6 437.1,12.6 428.8,36.5 428.5,36.5 420.5,12.6 412.5,12.6 "/>
<rect x="449" y="12.6" class="st1" width="7.3" height="31.2"/>
<path class="st1" d="M478.8,44.2c1.1,0,2.1-0.1,3.1-0.2c1-0.1,1.9-0.3,2.7-0.5c0.8-0.2,1.6-0.4,2.3-0.7V37c-1.3,0.4-2.5,0.7-3.8,0.9
	c-1.3,0.2-2.6,0.3-3.9,0.3c-1.5,0-2.7-0.1-3.8-0.5c-1.1-0.3-2-0.8-2.7-1.6c-0.7-0.8-1.2-1.8-1.5-3.1c-0.3-1.3-0.5-3-0.5-5
	c0-1.9,0.2-3.5,0.5-4.8c0.3-1.3,0.9-2.3,1.6-3.1c0.7-0.8,1.6-1.3,2.7-1.7c1.1-0.3,2.4-0.5,3.9-0.5c1.4,0,2.7,0.1,3.9,0.3
	c1.2,0.2,2.3,0.5,3.4,0.8v-5.9c-1-0.3-2.2-0.6-3.5-0.8c-1.3-0.2-2.7-0.3-4.3-0.3c-2.4,0-4.7,0.3-6.6,0.8c-2,0.5-3.7,1.4-5.1,2.7
	c-1.4,1.2-2.5,2.9-3.2,4.9c-0.8,2.1-1.1,4.6-1.1,7.6c0,2.6,0.2,4.8,0.7,6.7c0.5,1.9,1.2,3.4,2.1,4.6c0.9,1.2,2,2.2,3.4,2.9
	c1.3,0.7,2.8,1.2,4.5,1.5C475,44.1,476.8,44.2,478.8,44.2"/>
<path class="st1" d="M528,44.2c3,0,5.5-0.4,7.4-1.1c1.9-0.7,3.3-1.8,4.1-3.2c0.9-1.4,1.3-3.1,1.3-5.2c0-1.4-0.1-2.7-0.4-3.7
	c-0.3-1.1-0.8-2-1.5-2.7c-0.7-0.8-1.7-1.4-3-2c-1.3-0.6-2.9-1.1-4.9-1.5c-1.2-0.3-2.2-0.5-2.9-0.8c-0.8-0.2-1.3-0.5-1.7-0.8
	c-0.4-0.3-0.7-0.6-0.8-1c-0.1-0.4-0.2-0.8-0.2-1.3c0-0.9,0.2-1.6,0.6-2c0.4-0.5,0.9-0.8,1.7-1c0.8-0.2,1.8-0.3,3.1-0.3
	c0.7,0,1.4,0,2.2,0.1c0.8,0.1,1.6,0.2,2.3,0.3c0.8,0.1,1.5,0.2,2.1,0.4c0.6,0.1,1.2,0.3,1.6,0.4v-5.6c-0.9-0.3-2.1-0.6-3.5-0.8
	c-1.4-0.2-3.1-0.3-5-0.3c-2.6,0-4.8,0.3-6.6,0.9c-1.8,0.6-3.2,1.6-4.1,2.9c-0.9,1.3-1.4,3.1-1.4,5.3c0,1.4,0.1,2.6,0.4,3.5
	c0.3,1,0.8,1.8,1.5,2.5c0.7,0.7,1.6,1.2,2.8,1.7c1.2,0.5,2.7,0.9,4.4,1.3c1.3,0.3,2.3,0.6,3.1,0.8c0.8,0.3,1.4,0.6,1.8,0.9
	c0.4,0.3,0.7,0.7,0.9,1.1c0.2,0.4,0.2,0.9,0.2,1.5c0,0.9-0.2,1.7-0.5,2.2c-0.4,0.5-1,0.9-1.8,1.2c-0.9,0.2-2,0.3-3.5,0.3
	c-0.8,0-1.7,0-2.6-0.1c-0.9-0.1-1.7-0.1-2.6-0.3c-0.8-0.1-1.6-0.2-2.3-0.4c-0.7-0.2-1.3-0.3-1.9-0.5v6c0.7,0.2,1.5,0.4,2.6,0.5
	c1,0.2,2.1,0.3,3.3,0.4C525.4,44.1,526.7,44.2,528,44.2"/>
<polygon class="st2" points="499.2,18.5 507.7,18.5 512.2,18.5 512.2,12.6 491.9,12.6 491.9,18.5 "/>
<polygon class="st1" points="499.2,37.8 499.2,30.6 511.2,30.6 511.2,24.9 507.7,24.9 499.2,24.9 491.9,24.9 491.9,43.7 512.8,43.7 
	512.8,37.8 "/>
        </svg>
      </router-link>
    </h1>
    <ul class="navs">
      <li><router-link to="/about">About Us</router-link></li>
      <li><router-link to="/plasmid">Plasmid</router-link></li>
      <li @click="PopShow"><a href="javascript:;">mRNA</a></li>
      <li><router-link to="/contact">Contact Us</router-link></li>
      
    </ul>
    <div class="lets"><span>Let’s Scroll</span></div>
    <div class="menubtn" :class="{'active': menuStatusMobile}" @click="menuToggle()"><span></span></div>
    <div class="mobile-menu" :class="{'show': menuStatusMobile}">
      <ul class="mobile-menu-list">
        <li><router-link to="/">Home</router-link></li>
        <li><router-link to="/about">About Us</router-link></li>
        <li><router-link to="/plasmid">Plasmid</router-link></li>
        <li @click="PopShow"><a href="javascript:;">mRNA</a></li>
        <li><router-link to="/contact">Contact Us</router-link></li>
        
      </ul>
      <!-- <div class="mobile-share">
        <a href="#" class="iconfont">&#xe6e5;</a>
        <a href="#" class="iconfont">&#xe6ce;</a>
        <a href="#" class="iconfont">&#xf274;</a>
        <a href="#" class="iconfont">&#xe883;</a>
      </div> -->
    </div>
    <Netwrok :class="{'show': networkStatus}"></Netwrok>
  </div>
</template>
<script>
import Netwrok from './Netwrok.vue'
export default {
  data () {
    return {
      menuStatus: false,
      menuStatusMobile: false,
      offsetTops: 30,
      networkStatus: false
    }
  },
  components: {
    Netwrok
  },
  methods: {
    menuToggle () {
      this.menuStatusMobile = !this.menuStatusMobile
      if (this.menuStatusMobile) {
        window.document.body.style.overflow = 'hidden'
      } else {
        window.document.body.style.overflow = 'visible'
      }
    },
    PopShow(){
      this.networkStatus = true
    },
    PopClose() {
      this.networkStatus = false
    },
    handleScroll () {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      if (scrollTop >= this.offsetTops) {
        this.menuStatus = true
      } else {
        this.menuStatus = false
      }
    },
    addHeaderColor(){
      this.menuStatus = true
    },
    removeHeaderColor() {
      this.menuStatus = false
    }
  },
  mounted () {
    window.addEventListener('scroll', this.handleScroll)
  },
  destroyed () {
    window.addEventListener('scroll', this.handleScroll)
  },
  watch: {
    $route (to, from) {
      this.menuStatusMobile = false
      window.document.body.style.overflow = 'visible'
    }
  }
}
</script>
<style lang="less" scoped>
  .header-box{
    position: fixed;
    left: 0;
    top: 0;
    padding: 50px 9.8% 50px 4.166%;
    box-sizing: border-box;
    width: 100%;
    z-index: 199;
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: 0.2s;
    &.hide{
      opacity: 0;
      pointer-events: none;
    }
    &.scrollBg{
      background: #fff;
      padding: 24px 9.8% 24px 4.166%;
      .logo{
        svg{
          .st0{fill-rule:evenodd;clip-rule:evenodd;fill:url(#SVGID_1_);}
          .st1{fill:#595757;}
          .st2{fill:#EB6100;}
        }
      }
      .navs li{
        a{
          color: @fontColor;
        }
      }
    }
    &.homeBg{
      .menubtn{
        span{
          &:before,&:after{
            background: #1a222d;
          }
        }
      }
      .logo{
        svg{
          .st0{fill-rule:evenodd;clip-rule:evenodd;fill:url(#SVGID_1_);}
          .st1{fill:#595757;}
          .st2{fill:#EB6100;}
        }
      }
      .navs li{
        a{
          color: @fontColor;
        }
      }
    }
  }
  .logo{
    display: block;
    width: auto;
    height: 30px;
    line-height: 1;
    svg{
      display: block;
      max-width: 100%;
      height: 100%;
      transition: 0.3s;
    }
  }
  .st0{fill:url(#SVGID_1_); transition: 0.3s;}
	.st1{fill:#FFFFFF; transition: 0.3s;}
	.st2{fill:#EB6100; transition: 0.3s;}
  .navs{
    display: flex;
    li{
      margin-left: 74px;
      &:first-child{
        margin-left: 0;
      }
      a{
        display: inline-block;
        padding: 13px 0;
        position: relative;
        color: #fff;
        font-size: 16px;
        line-height: 1;
        font-family: "Poppins-SemiBold";
        &:hover,&.router-link-exact-active{
          &:before{
            transform: scaleX(1);
            opacity: 1;
          }
        }
        &:before{
          position: absolute;
          left: 0;
          bottom: 0;
          content: "";
          width: 100%;
          height: 3px;
          .gradient(90deg);
          opacity: 0;
          transform: scaleX(0);
          transition: 0.3s;
        }
      }
    }
  }
  .lets{
    position: absolute;
    right: 0;
    top: 0;
    z-index: 3;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80px;
    height: 183px;
    .gradient(90deg);
    border-radius: 0 0 0 20px;
    span{
      display: inline-block;
      position: relative;
      color: #fff;
      font-size: 16px;
      font-weight: 400;
      line-height: 1px;
      transform: rotate(90deg);
      text-transform: uppercase;
      white-space: nowrap;
    }
  }
  .menubtn {
    display: none;
    position: relative;
    width: 48px;
    height: 20px;
    z-index: 20;
    cursor: pointer;
    transition: 0.4s;
  }

  .menubtn span {
    display: block;
    margin-top: 8px;
    width: 100%;
    height: 2px;
    position: relative;
    -webkit-transition-duration: .3s, .3s;
    -moz-transition-duration: .3s, .3s;
    -ms-transition-duration: .3s, .3s;
    -o-transition-duration: .3s, .3s;
    transition-duration: .3s, .3s;
    -webkit-transition-delay: .3s, 0s;
    -moz-transition-delay: .3s, 0s;
    -ms-transition-delay: .3s, 0s;
    -o-transition-delay: .3s, 0s;
    transition-delay: .3s, 0s;
    transition: background 0.3s 0s;
  }

  .menubtn span:after,
  .menubtn span:before {
    content: "";
    position: absolute;
    display: inline-block;
    width: 100%;
    height: 2px;
    right: 0;
    background-color: #fff;
    -webkit-transition-duration: .3s, .3s;
    -moz-transition-duration: .3s, .3s;
    -ms-transition-duration: .3s, .3s;
    -o-transition-duration: .3s, .3s;
    transition-duration: .3s, .3s;
    -webkit-transition-delay: .3s, 0s;
    -moz-transition-delay: .3s, 0s;
    -ms-transition-delay: .3s, 0s;
    -o-transition-delay: .3s, 0s;
    transition-delay: .3s, 0s;
    transition: background 0.3s 0s;
  }
  .menubtn span:before {
    top: -5px;
    -webkit-transition-property: top, transform;
    -moz-transition-property: top, transform;
    -ms-transition-property: top, transform;
    -o-transition-property: top, transform;
    transition-property: top, transform;
  }
  .menubtn span:after {
    width: 80%;
    bottom: -5px;
    -webkit-transition-property: bottom, transform;
    -moz-transition-property: bottom, transform;
    -ms-transition-property: bottom, transform;
    -o-transition-property: bottom, transform;
    transition-property: bottom, transform;
  }
  .menubtn.active span {
    background: transparent;
    -webkit-transition-delay: 0s, 0s;
    -moz-transition-delay: 0s, 0s;
    -ms-transition-delay: 0s, 0s;
    -o-transition-delay: 0s, 0s;
    transition-delay: 0s, 0s;
  }
  .menubtn.active span:after,
  .menubtn.active span:before {
    -webkit-transition-delay: 0s, .3s;
    -moz-transition-delay: 0s, .3s;
    -ms-transition-delay: 0s, .3s;
    -o-transition-delay: 0s, .3s;
    transition-delay: 0s, .3s;
  }
  .menubtn.active span:before {
    top: 0px;
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg)
  }
  .menubtn.active span:after {
    bottom: 0px;
    width: 100%;
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
  .mobile-menu{
    position: fixed;
    left: 100%;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background:url("../../assets/images/mobile.jpg") center bottom no-repeat;
    background-size: cover;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    opacity: 0;
    visibility: hidden;
    transition: 0.8s;
    pointer-events: none;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    &.show{
      opacity: 1;
      visibility: visible;
      left: 0;
      pointer-events: visible;
      .mobile-menu-list{
        li{
          opacity: 1;
          visibility: visible;
          transform: translateY(0px);
          transition-delay: 0.3s;
          &:nth-child(2){
            transition-delay: 0.4s;
          }
          &:nth-child(3){
            transition-delay: 0.5s;
          }
          &:nth-child(4){
            transition-delay: 0.6s;
          }
          &:nth-child(5){
            transition-delay: 0.8s;
          }
        }
      }
      .mobile-share{
        opacity: 1;
        visibility: visible;
        transform: translateY(0);
        transition-delay: 0.75s;
      }
    }
  }
  .mobile-menu-list{
    padding-top: 30vw;
    padding-left: 8vw;
    li{
      margin-bottom: 9.6vw;
      opacity: 0;
      visibility: hidden;
      transform: translateY(40px);
      transition: 0.68s 0.5s;
      a{
        display: inline-block;
        color: #202a45;
        font-size: 9.6vw;
        line-height: 1;
      }
    }
  }
  .mobile-share{
    margin-left: 8vw;
    margin-top: 5vw;
    padding-bottom: 13.33vw;
    display: flex;
    align-items: flex-end;
    opacity: 0;
    visibility: hidden;
    transform: translateY(40px);
    transition: 0.68s 0.5s;
    a{
      display: inline-block;
      margin-right: 5.87vw;
      line-height: 1;
      color: @fontColor;
      font-size: 5.87vw;
    }
  }

  @media screen and (max-width: 1450px){
    .header-box{
      padding: 40px 9.8% 40px 4.166%;
    }
    .lets{
      width: 68px;
      height: 160px;
      span{
        font-size: 14px;
      }
    }
    .navs li{
      margin-left: 50px;
    }
  }
  @media screen and (max-width: 1024px){
    .lets{
      display: none;
    }
    .navs{
      display: none;
    }
    .header-box{
      padding: 46px 8%;
      &.scrollBg{
        padding: 24px 8%;
        .menubtn{
          span:before,span:after{
            background: #1a222d;
          }
        }
      }
    }
    .menubtn{
      display: block;
    }

  }
  @media screen and (max-width: 750px){
    .header-box{
      padding: 24px 9%;
    }
    .header-box.scrollBg{
      padding: 24px 9%;
    }
    .logo{
      height: 5.33vw;
    }
    .menubtn{
      width: 6.67vw;
      height: 2.67vw;
    }
    .menubtn span{
      margin-top: 1.07vw;
    }
  }
</style>

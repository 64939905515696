import Vue from 'vue'
import {Form,FormItem,Input,Select,Option,Button,Message} from 'element-ui'
// import 'element-ui/lib/theme-chalk/index.css'

Vue.use(Form)
Vue.use(FormItem)
Vue.use(Input)
Vue.use(Select)
Vue.use(Option)
Vue.use(Button)
Vue.prototype.$message = Message;
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VueAnimateOnScroll from 'vue-animate-onscroll'
import axios from 'axios'
import './plugins/element.js'

import './assets/css/global.css'
import './assets/css/animate.min.css'
import 'swiper/dist/css/swiper.css'
import 'element-ui/lib/theme-chalk/index.css'
Vue.config.productionTip = false
Vue.use(VueAnimateOnScroll)
Vue.prototype.$http = axios
Vue.prototype.$ip = 'https://startbioservices.com/'
axios.defaults.baseURL = 'https://startbioservices.com/yunXinZhiLiServer/api/client'

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')

<template>
  <div id="app">
    <headerBox :class="{'hide': showStaus}"></headerBox>
    <router-view/>
    
    <div class="common-footer">
      <footerBox v-show="$route.path != '/'"/>
    </div>
  </div>
</template>
<script>
import headerBox from '@/components/common/headerBox.vue'
import footerBox from '@/components/common/footerBox.vue'
export default {
  data () {
    return {
      showStaus: false
    }
  },
  components: {
    headerBox,
    footerBox
  },
  methods: {
    fadeHeader () {
      this.showStaus = true
    },
    hideHeader () {
      this.showStaus = false
    }
  }
}
</script>

<style lang="less" scoped>
</style>

<template>
  <div class="build-box">
    <div class="build-bg"></div>
    <div class="build-wrap">
      <img src="https://usa-web-oss.oss-us-west-1.aliyuncs.com/云心质力/images/16.png" alt=""/>
      <p>Coming Soon</p>
      <span @click="backPop">Return</span>
    </div>
  </div>  
</template>

<script>
export default {
  methods: {
    backPop() {
      this.$parent.PopClose()
    }
  }
}
</script>

<style lang="less" scoped>
  .build-box{
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 199;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    transition: 0.78s;
    &.show{
      opacity: 1;
      visibility: visible;
      pointer-events: visible;
    }
  }
  .build-bg{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.6);
  }
  .build-wrap{
    width: 620px;
    position: absolute;
    left: 50%;
    margin-left: -310px;
    top: 50%;
    transform: translateY(-50%);
    padding: 60px 0;
    background: #fff;
    border-radius: 20px;
    text-align: center;
    img{
      display: inline-block;
      width: 120px;
      height: auto;
    }
    p{
      margin-top: 20px;
      color: #000000;
      font-size: 28px;
      font-weight: bold;
    }
    span{
      display: inline-block;
      margin-top: 28px;
      width: 156px;
      height: 46px;
      text-align: center;
      border-radius: 23px;
      background: @themeColor;
      color: #fff;
      font-size: 16px;
      line-height: 46px;
      cursor: pointer;
      transition: 0.3s;
      &:hover{
        transform: translateY(-4px);
      }
    }
  }
  @media screen and (max-width:750px){
    .build-wrap{ width: 90%; margin-left: -45%;}
    .build-wrap img{ width: 80px;}
    .build-wrap p{ font-size: 22px;}
    .build-wrap span{ margin-top: 22px; width: 140px; height: 42px; line-height: 42px; font-size: 14px;}
  }
</style>
<template>
  <footer class="footers">
    <div class="footer-top">
      <div class="footer-logo slidetop" v-animate-onscroll="'animateMove'">
        <router-link to="/">
          <img src="https://usa-web-oss.oss-us-west-1.aliyuncs.com/云心质力/images/footerl.png" alt=""/>
        </router-link>
      </div>
      <!-- <div class="address slideList" v-animate-onscroll="'animateMove'">
        <div class="address-item">
          <span>address</span>
          <p>3-5/F, Block A, No. 117 Yifeng Road, Qiantang New District, Hangzhou, Zhejiang Province, China</p>
        </div>
        <div class="address-item">
          <span>E-mail</span>
          <p>bd@startbioservices.com</p>
        </div>
        <div class="address-item">
          <span>Tel</span>
          <p>+86 150 5819 3798</p>
        </div> 
      </div> -->
    </div>
    <div class="footer-bottom">
      <div class="footer-copy slidetop detay1" v-animate-onscroll="'animateMove'">
        <p>© 2023 by  start bioservices  Co., Ltd. ALL RIGHTS RESERVED</p>
        <!-- <p><a href="https://beian.miit.gov.cn" target="_block">浙ICP备2022008849号</a></p> -->
      </div>
      <div class="footer-share slidetop detay2" v-animate-onscroll="'animateMove'">
        <div class="emain">
          <i class="iconfont">&#xe604;</i>
          <p><a href="mailto:bd@startbioservices.com">bd@startbioservices.com</a></p>
        </div>
        <!-- <div class="share">
          <a href="#" class="iconfont">&#xe6e5;</a>
          <a href="#" class="iconfont">&#xe6ce;</a>
        </div> -->
      </div>
      
    </div>
    <div class="scroll-top" @click="scrollTop">
      <div class="scroll-wr">
        <i class="iconfont">&#xe64a;</i>
        <span>Back Top</span>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  methods: {
    scrollTop () {
      var timer = setInterval(function () {
        const osTop =
          document.documentElement.scrollTop || document.body.scrollTop
        const ispeed = Math.floor(-osTop / 5)
        document.documentElement.scrollTop = document.body.scrollTop =
          osTop + ispeed
        this.isTop = true
        if (osTop === 0) {
          clearInterval(timer)
        }
      }, 30)
      if (this.$parent.$parent.swiper && this.$parent.$parent.swiper.activeIndex == 1) {
        this.$parent.$parent.$parent.chageScroll1()
      }
    }
  }
}
</script>

<style lang="less" scoped>
  .footers{
    position: relative;
    padding: 165px 9.375% 76px;
    background: @fontColor;
    overflow: hidden;
  }
  .footer-top{
    display: flex;
    justify-content: space-between;
  }
  .footer-logo{
    display: block;
    width: 387px;
    img{
      display: block;
      width: 100%;
      height: auto;
    }
  }
  .address{
    display: flex;
    justify-content: space-between;
    width: 56.16%;
  }
  .address-item{
    span{
      display: block;
      margin-bottom: 26px;
      line-height: 1;
      color: #FFFFFF;
      font-size: 18px;
      opacity: 0.3;
      text-transform: uppercase;
    }
    p{
      color: #FFFFFF;
      font-size: 20px;
      line-height: 1.2;
      max-width: 387px;
    }
  }
  .footer-bottom{
    margin-top: 7.81vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .footer-share{
    display: flex;
    a{
      display: inline-block;
      color: #fff;
      line-height: 1;
      font-size: 28px;
      &:hover{
        background: linear-gradient(0deg, #FF934E 0%, #FC7F68 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
  }
  .footer-copy{
    width: 56.16%;
    p{
      display: inline-block;
      margin-right: 18px;
      color: #fff;
      font-size: 14px;
      text-transform: uppercase;
      a{
        font-size: 14px;
        color: #fff;
      }
      &:last-child{
        margin-right: 0;
      }
    }
  }
  .scroll-top{
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 3;
    width: 80px;
    height: 183px;
    .gradient(0deg);
    border-radius: 20px 0 0 0;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    &:hover{
      .scroll-wr{
        i{
          transform: rotate(180deg) translateX(6px);
        }
      }
    }
  }
  .scroll-wr{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: rotate(90deg);
    i{
      display: inline-block;
      margin-right: 18px;
      color: #fff;
      font-size: 22px;
      font-style: normal;
      line-height: 1;
      text-align: center;
      transform: rotate(180deg);
      line-height: 28px;
      transition: 0.2s;
    }
    span{
      display: inline-block;
      color: #fff;
      font-size: 16px;
      font-family: "Poppins-regular";
      text-transform: uppercase;
      white-space: nowrap;
    }
  }
  .share-item{
    position: relative;
    &:hover{
      .share-pop{
        opacity: 1;
        visibility: visible;
        transform: translateY(0px);
        pointer-events: visible;
      }
    }
  }
  .share-pop{
    position: absolute;
    left: 50%;
    margin-left: -60px;
    bottom: 100%;
    width: 120px;
    opacity: 0;
    visibility: hidden;
    transform: translateY(-30px);
    pointer-events: none;
    transition: 0.3s;
    figure{
      display: block;
      position: relative;
      line-height: 1;
      padding-bottom: 12px;
      &:before{
        position: absolute;
        left: 50%;
        margin-left: -5px;
        bottom: 5px;
        content: "";
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 7px solid #fff;
      }
      img{
        display: block;
        width: 100%;
        height: auto;
      }
    }
  }
  .footer-share{
    display: flex;
    align-items: center;
  }
  .emain{
    display: flex;
    align-items: center;
    i{
      display: inline-block;
      margin-right: 18px;
      color: #fff;
      font-size: 22px;
      line-height: 1;
    }
    p{
      color: #fff;
      font-size: 20px;
      a{
        font-size: 20px;
      }
    }
  }
  .share{
    margin-left: 35px;
    a{
      display: inline-block;
      margin-right: 30px;
      color: #fff;
      font-size: 24px;
      line-height: 1;
      &:hover{
        color: @themeColor;
      }
      &:last-child{
        margin-right: 0;
      }
    }
  }
  @media screen and (max-width: 1600px){
    .footers{
      padding: 8.59% 9.375% 3.95%;
    }
    .footer-logo{
      width: 300px;
    }
    .address-item span{
      font-size: 16px;
      margin-bottom: 18px;
    }
    .address-item p{
      padding-right: 10px;
      font-size: 18px;
      max-width: 320px;
    }
    .address-item:last-child{
      p{
        padding-right: 0;
      }
    }
    .address,.footer-copy{
      width: 62%;
    }
  }
  @media screen and (max-width: 1360px){
    .footers{
      padding: 8.59% 6% 3.95%;
    }
    .footer-share{ 
      margin-right: 30px;
    }
  }
  @media screen and (min-width: 750px){
    .footer-logo,.address-item,.footer-share,.footer-copy{
      opacity: 0;
      visibility: hidden;
      transform: translateY(50px);
      transition: 0.8s 0.3s;
    }
  }
  @media screen and (max-width: 750px){
    .footers{
      padding: 20.53vw 0 0;
    }
    .scroll-top{
      bottom: auto;
      top: 0;
      border-radius: 0 0 0 2vw;
      width: 12.4vw;
      height: 28.53vw;
    }
    .address-item p{
      max-width: 80%;
      padding-right: 0;
      font-size: 3.2vw;
      line-height: 1.4;
    }
    .scroll-wr{
      top: 23%;
    }
    .scroll-wr i{
      font-size: 4vw;
      margin-right: 2.67vw;
    }
    .scroll-wr span{
      font-size: 3.2vw;
    }
    .footer-top{
      padding: 0 8vw;
      display: block;
    }
    .footer-logo{
      margin-bottom: 12.27vw;
      width: 52.27vw;
    }
    .address{
      display: block;
      width: 100%;
    }
    .address-item{
      margin-bottom: 9.33vw;
      span{
        margin-bottom: 2.93vw;
        font-size: 2.93vw;
        line-height: 1.4;
      }
    }
    .footer-share.slidetop,.footer-copy.slidetop{ opacity: 1; visibility: visible; transform: translateY(0);}
    .footer-bottom{
      margin-top: 0;
      padding: 9.07vw 8vw 8vw;
      flex-direction: column-reverse;
      border-top: 1px solid rgba(255,255,255,0.3);
    }
    .footer-share{
      justify-content: flex-start;
      width: 100%;
      margin-right: 0;
    }
    .footer-share a{
      margin-right: 4.27vw;
      font-size: 4.53vw;
    }
    .footer-copy{
      margin-top: 4.27vw;
      display: block;
      width: 100%;
      p{
        font-size: 2.9vw;
        line-height: 1.2;
        a{
          font-size: 2.9vw;
        }
      }
    }
    .emain p{ font-size: 3.2vw; a{ font-size: 3.2vw;}} 
    .emain{ margin-top: 0.52vw;}
    .emain i{ margin-right: 8px; font-size: 16px;}
  }
</style>
